<template>
  <div :key="`tasks/${taskFilter}`">
    <algolia-task-list
      :user-id="userId"
      :task-filter="taskFilter"
      :route-func="
        hit => ({ path: `/admin/tasks/${taskFilter}/${hit.taskId}` })
      "
    >
      <template slot="nosearch" slot-scope="{ isEmpty }">
        <list
          v-show="isEmpty"
          ref="list"
          :reference="reference"
          :sorters="sorters"
          :active-sort="defaultSort"
        >
          <template slot-scope="task">
            <list-card
              :title="`${task.item.reference} `"
              :color="$_.get(task.item, `colors.${userId}`, '')"
              :subtitle-dark="
                task.item.subject
                  ? `${task.item.taskType}: ${task.item.subject}`
                  : task.item.taskType
              "
              :subtitle-grey="
                `${task.item.fqdn || ''}
              (${getEmail(task.item)})`.replace(/\(\)$/, '')
              "
              :label="$store.getters['tasks/task/status'](task.item.status)"
              :timestamp="getTimestamp(task.item)"
              :avatar="getAvatar(task.item)"
              :route="{ path: `/admin/tasks/${taskFilter}/${task.item._id}` }"
            >
              <strong slot="title" class="is-spaced-apart">
                {{ task.item.reference }}
                <b-icon
                  v-if="getPriorityIcon(task.item.priority)"
                  v-bind="getPriorityIcon(task.item.priority)"
                />
              </strong>
            </list-card>
          </template>
        </list>
      </template>
    </algolia-task-list>
  </div>
</template>

<script>
import { statuses, getPriorityIcon } from "@src/data/task";
import { collection, orderBy, query, where } from "@firebase/firestore";
export default {
  name: "AdminTasks",
  components: {
    "algolia-task-list": () => import("@shared/tasks/_algoliaTaskList.vue")
  },
  computed: {
    userId() {
      return this.$store.getters["auth/userId"]();
    },
    taskFilter() {
      return this.$route.params.taskFilter;
    },
    validFilter() {
      return [
        "attention-required",
        "my",
        "unassigned",
        "queued",
        "malware-and-security",
        "waiting-for-reply",
        "scheduled",
        "all",
        "all-closed"
      ].includes(this.taskFilter);
    },
    reference() {
      switch (this.taskFilter) {
        case "all":
          return query(
            collection(this.$firestore, "tasks"),
            where("status", ">", "1-02"),
            where("team", "==", null),
            orderBy("status", "desc")
          );
        case "attention-required":
          return query(
            collection(this.$firestore, "tasks"),
            where("needsAttention", "==", true)
          );
        case "my":
          return query(
            collection(this.$firestore, "tasks"),
            where("status", ">", "1-02"),
            where("agents", "array-contains", this.userId),
            orderBy("status", "desc")
          );
        case "unassigned":
          return query(
            collection(this.$firestore, "tasks"),
            where("status", ">", "1-01"),
            orderBy("status", "desc"),
            where("agents", "==", [])
          );
        case "queued":
          return query(
            collection(this.$firestore, "tasks"),
            where("status", "==", statuses.QUEUED)
          );
        case "scheduled":
          return query(
            collection(this.$firestore, "tasks"),
            where("status", "==", statuses.SCHEDULED)
          );
        case "malware-and-security":
          return query(
            collection(this.$firestore, "tasks"),
            where("status", "==", statuses.MALWARE_AND_SECURITY)
          );
        case "waiting-for-reply":
          return query(
            collection(this.$firestore, "tasks"),
            where("status", "==", statuses.WAITING_FOR_REPLY)
          );
        case "all-closed":
          return query(
            collection(this.$firestore, "tasks"),
            where("status", "<", "1-"),
            orderBy("status", "desc")
          );
        default:
          return query(
            collection(this.$firestore, "tasks"),
            orderBy("status", "desc")
          );
      }
    },
    sorters() {
      switch (this.taskFilter) {
        case "scheduled":
          return [
            {
              field: "dateScheduled",
              label: "Date Scheduled"
            },
            {
              field: "dateCreated",
              label: "Date Created"
            }
          ];
        default:
          return [
            {
              field: "dateUpdated",
              label: "Last Updated"
            },
            {
              field: "dateCreated",
              label: "Date Created"
            }
          ];
      }
    },
    defaultSort() {
      switch (this.taskFilter) {
        case "unassigned":
        case "queued":
          return { field: "dateCreated", direction: "asc" };
        case "scheduled":
          return { field: "dateScheduled", direction: "asc" };
        case "attention-required":
        case "all-closed":
        case "my":
        default:
          return { field: "dateUpdated", direction: "desc" };
      }
    }
  },
  watch: {
    taskFilter() {
      this.$nextTick(() => {
        // this.$refs.algoliaSearch.$el.querySelector("input").focus();
      });
    }
  },
  created() {
    if (!this.validFilter) {
      this.$router.push({ path: `/admin/tasks` });
    }
  },
  mounted() {
    this.$nextTick(() => {
      // this.$refs.algoliaSearch.$el.querySelector("input").focus();
    });
  },
  methods: {
    getPriorityIcon,
    getAvatar(task) {
      if (this.$_.isEmpty(task.alphaAgent)) return null;
      return {
        name: this.$_.get(
          task,
          `participantsData.${task.alphaAgent.id}.name`,
          ""
        ),
        url: this.$_.get(
          task,
          `participantsData.${task.alphaAgent.id}.avatarUrl`,
          ""
        )
      };
    },
    getTimestamp(task) {
      return task[
        this.$_.get(this.$refs.list, "table.sort.field", this.defaultSort.field)
      ].toDate();
    },
    getEmail(task) {
      return this.$_.has(task, `participantsData.${task.authorId}`)
        ? task.participantsData[task.authorId].email
        : "";
    }
  }
};
</script>
